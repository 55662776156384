import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'
import application from './Proposal_Application.docx'

function Special_session() {
  return (
    <div className="container my-5">

          


      <div className="row">
        <div className="col-sm-6 mb-3 mb-sm-0">
              <div className="card border-0">
                <div className="card-body">
                  <h1 className="card-title">Call for Special Sessions</h1>

                  <h4 className="card-title border-bottom my-4">Invitation to conduct special session in NetCrypt 2025</h4>

                  {/* <h5 className=" card-text my-4">Dear Sir/Mam</h5> */}

                  <p className="card-text my-4">Greeting from NetCrypt-2025!</p>

                  <p className="card-text my-4" style={{textAlign: "justify"}}>NetCrypt Conference team invites you to organize the special session in the International Conference On Networks And Cryptology (NetCrypt-2025), to be held at The Jawaharlal Nehru University, New Delhi India on 29th - 31st May 2025 of your research domain. We are diligently working to bring some of the most knowledgeable researchers from all over the world along with the leaders from the industry to explore the important topics of research. The three day conference will include workshop, technical sessions and keynotes on cutting edge technologies related to our industry. The URL for the conference: www.netcrypt.org.in Kindly increase the value of the conference by being part of this conference as a session chair and heading your own session in this esteemed conference. We are aware about your networking skills and of having an eye to identify quality research papers.</p>

                  <h4 className="card-text my-4">Highlights of the conference and Special Session Benefits:</h4>

                  <ol>
                    {/* <li className="my-2" style={{textAlign:'justify'}}>Proceedings in the reputed Scopus indexed Springer series (Approved).</li> */}
                    {/* <li className="my-2" style={{textAlign:'justify'}}>You will get 50% discount on any two selected papers.</li> */}
                    <li className="my-2" style={{textAlign:'justify'}}>The minimum number of registrations should be 10.</li>

                    {/* <li className="my-2" style={{textAlign:'justify'}}>Extended papers in SCI/Scopus/ESCI/DBLP/ACM Digital Library journals. We have already got approval from some Scopus and high quality journals. (Soon will be declared).</li> */}

                    <li className="my-2" style={{textAlign:'justify'}}>
                    Extended papers expected to go to IEEE xplore, subject to getting screened through technical program. </li> 

                    

                    <li className="my-2" style={{textAlign:'justify'}}>Opportunity to be Session chair at the conference.</li>
                    {/* <li className="my-2" style={{textAlign:'justify'}}>Extra weightage and support will be provided for your session extended paper in SCI/ Scopus Journal.</li> */}
                    <li className="my-2" style={{textAlign:'justify'}}>You will get the opportunity to listen international and national key note speakers in the conference.</li>
                    <li className="my-2" style={{textAlign:'justify'}}>Easy accessibility of the well-connected conference venue.</li>
                  </ol>

                  <p className="card-text my-4" style={{textAlign:'justify'}}>If you are interested to conduct a special session, kindly mail us the special session proposal at chair@netcrypt.org.in latest by 30th January, 2025 as per the format attached with this mail.</p>

                  <p className="card-text my-4" style={{textAlign:"justify"}}><strong>All the accepted papers are expected to be submitted to IEEE Xplore after passing the Technical Program Integrity check upon presentation in the Conference, following acceptance after peer review.</strong> </p>

                  <p className="card-text my-4" style={{textAlign:'justify'}}>Looking to see you in NetCrypt-2025.</p>
                  <p className="card-text my-4" style={{textAlign:'justify'}}>Sincerely,</p>
                  {/* <p className="card-text my-4" style={{textAlign:'justify'}}>International Journal of Computer Applications [Impact Factor: 0.814]</p> */}
                  <p className="card-text my-4" style={{textAlign:'justify'}}>Organizer and Convener</p>
                  <p className="card-text my-4" style={{textAlign:'justify'}}>NetCrypt-2025</p>

                  <div className="my-5">
                      <a href={application} type="button" className="btn btn-outline-primary" download="Application_netcrypt">Special Session CFP Template</a>
                      
                      {/* <button type="button" className="btn btn-outline-primary">DOWNLOAD</button> */}
                  </div>

          {/* card for special session */}

          {/* special session 1 */}

          <div className="card my-5 shadow-lg p-3 mb-5 bg-white rounded">
                      <div className="card-header">
                        <h5 className='text-danger text-center'>Special Session 1</h5>
                        <h5 className='text-success text-center'>Advancements and Emerging Applications in
                        Computing and Intelligent Systems</h5>
                        

                      </div>
                      <div className="card-body">

                      <div className="col d-flex justify-content-center">

                      <div class="card text-bg-primary mb-3" style={{"max-width": "32rem"}}>
                        <div class="card-header" style={{"textAlign":"justify"}}> <p class="fw-bold">About the session: </p> The aim of this session is to provide researchers a platform to showcase their contributions in advances on the topics mentioned below. The field of Advanced Computing and Intelligent Engineering is rapidly evolving, driven by technological advancements and innovative research.</div>
                        <div class="card-body">
                          <ul>
                            <li className="my-3">
                              <h5 class="card-title">Dr. Anvesha Katti</h5>
                              <p class="card-text">Email: anveshakatti@gmail.com</p>
                              <p class="card-text">India</p>
                            </li>

                            <li className="my-3">
                              <h5 class="card-title">Dr. Priyanka Vashisht</h5>
                              <p class="card-text">Email: priyanka.vashisht@gmail.com</p>
                              <p class="card-text">India</p>
                            </li>
                          </ul>
                        
                          
                        </div>
                      </div>

                      </div>

                      </div>
                      <div className="card-footer text-center text-body-secondary">
                        Hybrid mode
                      </div>
            </div>

            {/* end special session 1 */}

            {/* special session 2 */}

          <div className="card my-5 shadow-lg p-3 mb-5 bg-white rounded">
                      <div className="card-header">
                        <h5 className='text-danger text-center'>Special Session 2</h5>
                        <h5 className='text-success text-center'>Advances in AI and ML-Driven Cybersecurity Solutions for IoT Ecosystems</h5>
                        

                      </div>
                      <div className="card-body">

                      <div className="col d-flex justify-content-center">

                      <div class="card text-bg-warning mb-3" style={{"max-width": "32rem"}}>
                        <div class="card-header" style={{"textAlign":"justify"}}> <p class="fw-bold">About the session: </p> This subject area invites research submissions focused on the integration of Artificial Intelligence (AI), Machine Learning (ML), and advanced cybersecurity techniques to address emerging threats and vulnerabilities within IoT ecosystems.</div>
                        <div class="card-body">
                          <ul>
                            <li className="my-3">
                              <h5 class="card-title">Dr. Danish Ather</h5>
                              <p class="card-text">Amity University</p>
                              <p class="card-text">Uzbekistan</p>
                            </li>
                          </ul>
                        
                          
                        </div>
                      </div>

                      </div>

                      </div>
                      <div className="card-footer text-center text-body-secondary">
                        Hybrid mode
                      </div>
            </div>

            {/* end special session 2 */}

            {/* special session 2 */}

          <div className="card my-5 shadow-lg p-3 mb-5 bg-white rounded">
                      <div className="card-header">
                        <h5 className='text-danger text-center'>Special Session 2</h5>
                        <h5 className='text-success text-center'>Advances in Quantum-Safe Cryptography: Securing Future
                        Networks</h5>
                        

                      </div>
                      <div className="card-body">

                      <div className="col d-flex justify-content-center">

                      <div class="card text-bg-info mb-3" style={{"max-width": "32rem"}}>
                        <div class="card-header" style={{"textAlign":"justify"}}> <p class="fw-bold">About the session: </p> In light of the rapid advancements in quantum computing, the security of traditional cryptographic systems is at significant risk. We invite researchers, practitioners, and industry experts to contribute to our special session titled "Advances in Quantum-Safe Cryptography: Securing Future Networks." This session aims to explore innovative cryptographic solutions that are resilient against quantum attacks and essential for securing future network architectures.</div>
                        <div class="card-body">
                          <ul>
                            <li className="my-3">
                              <h5 class="card-title">Dr. Rydhm Beri</h5>
                              <p class="card-text">Email: rydhm.beri@alliance.edu.in,</p>
                              <p class="card-text">India</p>
                            </li>
                          </ul>
                        
                          
                        </div>
                      </div>

                      </div>

                      </div>
                      <div className="card-footer text-center text-body-secondary">
                        Hybrid mode
                      </div>
            </div>

            {/* end special session 2 */}

            {/* special session 3 */}


            <div className="card my-5 shadow-lg p-3 mb-5 bg-white rounded">
                      <div className="card-header">
                        <h5 className='text-danger text-center'>Special Session 3</h5>
                        <h5 className='text-success text-center'>Computer security, generative AI, and bio-inspired computing applications</h5>
                        

                      </div>
                      <div className="card-body">

                      <div className="col d-flex justify-content-center">

                      <div class="card text-bg-danger mb-3" style={{"max-width": "32rem"}}>
                        <div class="card-header" style={{"textAlign":"justify"}}> </div>
                        <div class="card-body">
                          <ul>
                            <li className="my-3">
                              <h5 class="card-title">Dr. AJEET SINGH</h5>
                              <p class="card-text">Email: ajeetsinghiet@gmail.com</p>
                              <p class="card-text">India</p>
                            </li>
                          </ul>
                        
                          
                        </div>
                      </div>

                      </div>

                      </div>
                      <div className="card-footer text-center text-body-secondary">
                        Hybrid mode
                      </div>
            </div>

            {/* end special session 3 */}


               {/* special session 4 */}

          <div className="card my-5 shadow-lg p-3 mb-5 bg-white rounded">
                      <div className="card-header">
                        <h5 className='text-danger text-center'>Special Session 4</h5>
                        <h5 className='text-success text-center'>Advanced Data Science and machine learning in Cutting-Edge Applications with security measures</h5>
                        

                      </div>
                      <div className="card-body">

                      <div className="col d-flex justify-content-center">

                      <div class="card text-bg-info mb-3" style={{"max-width": "32rem"}}>
                        <div class="card-header" style={{"textAlign":"justify"}}> <p class="fw-bold">About the session: </p> Advanced Data Science and Machine Learning in Cutting-Edge Applications represent the frontier of technological innovation, where sophisticated analytics and intelligent algorithms converge to unlock unprecedented insights and drive transformative outcomes. In this dynamic landscape, data scientists leverage advanced methodologies to extract meaningful patterns and knowledge from vast and complex datasets. The integration of machine learning further augments these capabilities, enabling systems to adapt and evolve based on patterns, improving decision-making processes, and uncovering hidden correlations. </div>
                        <div class="card-body">
                          <ul>
                            <li className="my-3">
                              <h5 class="card-title">Prof. (Dr.) Anil Kumar Sagar</h5>
                              <p class="card-text">Email: aksagar22@gmail.com</p>
                              <p class="card-text">India</p>
                            </li>

                            <li className="my-3">
                              <h5 class="card-title">Dr. Dharm Raj</h5>
                              <p class="card-text">Email: dharmraj4u@gmail.com</p>
                              <p class="card-text">India</p>
                            </li>
                          </ul>
                        
                          
                        </div>
                      </div>

                      </div>

                      </div>
                      <div className="card-footer text-center text-body-secondary">
                        Hybrid mode
                      </div>
            </div>

            {/* end special session 4 */}

          

            {/* end card for special session */}
                  
                </div>
              </div>

              
            </div>

            <Ieee_banner />


            
      </div>
    </div>
  )
}

export default Special_session