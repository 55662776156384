import React from 'react'
import JNU from '../Images/JNUnewLogo_1.jpg';

function Steering() {
    
  return (

    <div className="container my-5">
        <h1 className="border-bottom"> COMMITTEE</h1>
        <ul className="my-5">

            <h3><span class="badge rounded-pill text-bg-success">CHIEF PATRON</span></h3>
            <li className='my-3'><h5>PROF. SANTISHREE DHULIPUDI PANDIT</h5>HON. VICE CHANCELLOR, JAWAHARLAL NEHRU UNIVERSITY, NEW DELHI</li>
        </ul>

        <ul className="my-5">

            <h3><span class="badge rounded-pill text-bg-success">PATRONS</span></h3>
            <li className='my-3'><h5>PROF. BRAJESH KUMAR PANDEY</h5>Rector-I, JAWAHARLAL NEHRU UNIVERSITY, NEW DELHI</li>
        </ul>

        <ul className="my-5">

            <h3><span class="badge rounded-pill text-bg-success">CO-PATRONS</span></h3>
            <li className='my-3'><h5>ZAHID RAZA</h5>SCHOOL OF COMPUTER & SYSTEMS SCIENCES,JAWAHARLAL NEHRU UNIVERSITY, NEW DELHI</li>
            <li className='my-3'><h5>ABDUL QUAIYUM ANSARI</h5>(IEEE CHAIRMAN), JAMIA MILLIA ISLAMIA, NEW DELHI</li>
        </ul>

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">GENERAL CHAIR </span></h3>
            {/* <li className='my-3'><h5>BHARAT K. BHARGAVA,</h5> DEPARTMENT OF COMPUTER SCIENCES, PURDUE UNIVERSITY, INDIANA, USA.</li> */}

            <li className='my-3'><h5>D. K. LOBIYAL</h5> SCHOOL OF COMPUTER AND SYSTEMS SCIENCES, JAWAHARLAL NEHRU UNIVERSITY, DELHI, INDIA </li>

            <li className='my-3'><h5>R. S. YADAV</h5>DEPARTMENT OF COMPUTER SCIENCES AND ENGINEERING, MOTILAL NEHRU NATIONAL INSTITUTE OF TECHNOLOGY, ALLAHBAD, INDIA</li>


            <li className="my-3"> <h5>KHAIROL AMALI BIN AHMAD</h5> NATIONAL DEFENCE UNIVERSITY OF MALASYSIA, MALAYSIA</li>



            
          

            {/* */}

            {/* <li className='my-3'><h5>ZAFAR U. AHMED</h5> PROFESSOR OF MARKETING AND INTERNATIONAL BUSINESS, BBA (New York), MBA (Texas), Ph.D., (Utah), D.Litt., (England - Candidate) </li> */}
        </ul>

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">CONFERENCE CHAIRS</span></h3>
            <li className='my-3'><h5>SUSHIL KUMAR</h5> SCHOOL OF COMPUTER AND SYSTEMS SCIENCES, JAWAHARLAL NEHRU UNIVERSITY, DELHI, INDIA </li>
            <li className='my-3'><h5>ALI AHMADIAN</h5> CENTRAL QUEENSLAND UNIVERSITY, ROCKHAMPTON, AUSTRALIA</li>
            <li className='my-3'><h5>SERGEY BEZZATEEV</h5> ITMO UNIVERSITY, ST. PETERSBURG, RUSSIA </li>
            <li className='my-3'><h5>ARVIND DAGUR</h5> GALGOTIAS UNIVERSITY, GREATER NOIDA, INDIA</li>

        </ul>

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">CONVENERS</span></h3>
            <li className='my-3'><h5>KARAN SINGH</h5> SCHOOL OF COMPUTER AND SYSTEMS SCIENCES, JAWAHARLAL NEHRU UNIVERSITY, DELHI, INDIA </li>
            <li className='my-3'><h5>BHAVNESH KUMAR</h5>DEPARTMENT OF INSTRUMENTATION & CONTROL ENGINEERING, NSUT, NEW DELHI, INDIA</li>
        </ul>

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">TECHNICAL PROGRAM CHAIRS</span></h3>
            <li className='my-3'><h5>ADITI SHARAN</h5> SCHOOL OF COMPUTER AND SYSTEMS SCIENCES, JAWAHARLAL NEHRU UNIVERSITY, DELHI, INDIA </li>
            <li className="my-3"> <h5>M N HODA</h5> BHARATI VIDYAPITH INSTITUTE OF COMPUTER APPLICATION AND MANAGEMENT, NEW DELHI, INDIA</li> 

             <li className="my-3"> <h5>H L MANDORIA</h5> GOVIND BALLABH PANT UNIVERSITY OF AGRICULTURE & TECHNOLOGY, PANTNAGAR, UK, INDIA   </li> 
            
            <li className='my-3'><h5>ANSHUL VARSHNEY</h5> NATIONAL PHYSICAL LABORATORY, DELHI, INDIA</li>
            <li className='my-3'><h5>ROSILAH BINTI HASSAN</h5>UNIVERSITI KEBANGSAAN MALAYSIA, MALAYSIA </li>
            <li className='my-3'><h5>R. K. DWIVEDI</h5>TMU, MORADABAD, INDIA</li>

            
        </ul>

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">FUND RAISING COMMITTEE</span></h3>
            
            <li className="my-3"><h5>SACHIN BALKRUSHNA JADHAV</h5>SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI</li>
            <li className="my-3"><h5>DEEPIKA SAINI</h5> GRAPHICERA DEEMED TO BE UNIVERSITY, DEHRADUN, INDIA</li>
            <li className='my-3'><h5>MANISH GUPTA</h5> UNIVERSITY OF TECHNOLOGY SYDNEY, AUSTRALIA</li>

            <li className='my-3'><h5>ANIL K SAGGAR</h5>   SHARDA UNIVERSITY, GREATER NOIDA, INDIA </li>
            
            
        </ul>

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">REGISTRATION COMMITTEE</span></h3>
            
            <li className='my-3'><h5>ASUTOSH SRIVASTAVA</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>
            <li className='my-3'><h5>AMIT KUMAR AWASTHI</h5> GAUTAM BUDDHA UNIVERSITY, INDIA </li>
            <li className='my-3'><h5>VINAY PATHK</h5>  IIIT SONEPAT, HARYANA, INDIA </li>

            <li className='my-3'><h5>R.P. OJHA</h5>  G. L. BAJAJ, GREATER NOIDA, UP, INDIA </li>
            <li className='my-3'><h5>DHIRENDRA KUMAR SHUKLA </h5> GALGOTIAS UNIVERSITY, GREATER NOIDA, INDIA </li>

            
            
            
        </ul>

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">FINANCE COMMITTEE</span></h3>
            <li className='my-3'><h5>POONAM AGARWAL</h5>SCHOOL OF COMPUTER AND SYSTEMS SCIENCES, JNU, DELHI, INDIA </li>
            <li className='my-3'><h5>SATVIK VATS</h5>GRAPHIC ERA HILL UNIVERSITY, DEHRADUN, UK, INDIA</li>
            
            
            <li className='my-3'><h5>SUBRATA SAHANA</h5>SHARDA UNIVERSITY, GREATER NOIDA, INDIA</li>
            
            <li className='my-3'><h5>VIKRANT SHARMA</h5>GRAPHIC ERA HILL UNIVERSITY, DEHRADUN, UK, INDIA</li> 

            <li className='my-3'><h5>SHABIR ALI </h5> GALGOTIAS UNIVERSITY, GREATER NOIDA, INDIA</li> 
            
        </ul>


       
        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">PUBLICATION COMMITTEE</span></h3>
            <li className="my-3"><h5>INDIVAR GUPTA</h5>SCIENTIFIC ANALYSIS GROUP, DRDO, INIDA</li>
            <li className='my-3'><h5>MANISHA MANJUL</h5> G. B. PANT DSEU CAMPUS-I, NEW DELHI, INDIA</li>

            <li className='my-3'><h5>AYESHA CHOUDHARY</h5> JAWAHARLAL NEHRU UNIVERSITY, NEW DELHI, INDIA</li>
            <li className='my-3'><h5>TAYYAB KHAN</h5>IIIT SONEPAT, HARYANA, INIDA</li>
            {/* <li className='my-3'><h5>NUR IZURA UDZIR</h5>UNIVERSITY OF PUTRA MALAYSIA, MALAYSIA</li> */}
            
        
            <li className='my-3'><h5>SOHEIL SALAHSHOUR</h5> BAHCESEHIR UNIVERSITY, ISTANBUL, TURKEY </li>

            <li className='my-3'><h5>NITIN SHUKLA</h5> BENNETT UNIVERSITY, GREATER NOIDA, INDIA </li>
            
            
        </ul>

        

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">POSTER AND DEMONSTRATION COMMITTEE</span></h3>
            <li className="my-3"><h5>BUDDHA SINGH</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>
            
            {/* <li className='my-3'><h5>ARVIND DAGUR</h5> GALGOTIAS UNIVERSITY, GREATER NOIDA, INDIA</li> */}
            <li className='my-3'><h5>ANIL KUMAR YADAV</h5>CHHATRAPATI SHAHU JI MAHARAJ UNIVERSITY, KANPUR, UP, INDIA</li>
            <li className='my-3'><h5>ZAFAR U. AHMED</h5> LEBANESE AMERICAN UNIVERSITY, USA </li>
            <li className='my-3'><h5>AL-SAKIB KHAN PATHAN</h5> UNITED INTERNATIONAL UNIVERSITY (UIU), BANGLADESH </li>

            <li className='my-3'><h5>VIVEK SRIVASTVA</h5> NIT UTTRAKHAND, INDIA </li>

            <li className='my-3'><h5>AMBIKA GUPTA </h5> GALGOTIAS UNIVERSITY, INDIA</li>
            
            
        </ul>

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">EVENT MANAGEMENT COMMITTEE</span></h3>
            <li className="my-3"><h5>TIRTHANKAR GAYEN</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>
            <li className="my-3"><h5>JASRAJ MEENA</h5>SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI</li>
            <li className='my-3'><h5>AJEET SINGH </h5> GALGOTIAS UNIVERSITY, GREATER NOIDA, INDIA</li>

            <li className='my-3'><h5>KUMAR RAHUL </h5> NIFTM SONEPAT, INDIA</li>
            <li className='my-3'><h5>RAVI SHANKAR SHUKLA  </h5>SAUDI ELECTRONIC UNIVERSITY, SAUDI ARABIA</li>

            <li className='my-3'><h5>AJAY KUMAR</h5>CENTRAL UNIVERSITY OF HIMACHAL PRADESH, DHARAMSHALA, INDIA</li>

            <li className='my-3'><h5>GAURAV RAJ </h5>SHARDA UNIVERSITY, GREATER NOIDA, INDIA</li>
            
            
            
            <li className="my-3"><h5>SANOJ KUMAR</h5> INDIAN INSTITUTE OF TECHNOLOGY ROORKEE, U.K., INDIA</li>
            <li className="my-3"><h5>NAVEEN KUMAR GUPTA</h5> DR B R AMBEDKAR NATIONAL INSTITUTE OF TECHNOLOGY, JALANDHAR</li>
            
            <li className='my-3'><h5>DAVID TANIAR</h5>MONASH UNIVERSITY, AUSTRALIA</li>
            
            <li className='my-3'><h5>AKHILESH KUMAR </h5> GALGOTIAS UNIVERSITY, GREATER NOIDA, INDIA</li>

            <li className='my-3'><h5>REENA KASANA</h5> UNIVERSITY OF DELHI, INDIA</li>

            <li className='my-3'><h5> MK SHARMA </h5> AMRAPALI UNIVERSITY, HALDWANI, INDIA</li>
            
            
            
        </ul>

        

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">WEB COMMITTEE</span></h3>
            
            <li className="my-3"><h5>SAURABH KUMAR SHARMA</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI</li>

            <li className="my-3"><h5>YOGENDRA MEENA</h5>SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI</li>
            
            
            <li className="my-3"><h5>LE HOANG SON</h5> VIETNAM NATIONAL UNIVERSITY, HANOI, VIETNAM</li>

            <li className="my-3"><h5>ASHISH KHANNA </h5> CSED, MAIT, DELHI, INDIA</li>

            <li className="my-3"><h5>S SANJIB KUMAR </h5>KIET, GHAZIABAD, DELHI-NCR INDIA</li>

            
            
            
        </ul>

        



       

        

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">PUBLICITY AND PUBLIC RELATIONS COMMITTEE</span></h3>

            <li className='my-3'><h5>POONGUZHALI N</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>
            <li className='my-3'><h5>SHEHZAD ASHRAF CHAUDHRY</h5> ISTANBUL GELISIM UNIVERSITY, ISTANBUL, TURKEY</li>
             
           <li className='my-3'><h5>AMANDEEP</h5>GJUST, HISAR, INDIA</li>

           <li className='my-3'><h5>PAWAN SINGH MEHRA </h5> DELHI TECHNOLOGICAL UNIVERSITY, NEW DELHI, INDIA</li>

           

           <li className='my-3'><h5>RABINDRA KUMAR BARIK </h5> KIIT UNIVERSITY, BHUBANESWAR, ODISHA, INDIA</li>
            
        </ul>

        <ul className="my-5">
            <h3><span class="badge rounded-pill text-bg-success">SPECIAL SESSION COMMITTEE</span></h3>
            <li className="my-3"><h5>VIKAS MITTAL</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI</li>

            <li className="my-3"><h5>YOGENDRA MOHAN </h5> NORTH EASTERN REGIONAL INSTITUTE OF SCIENCE AND TECHNOLOGY, ITANAGAR, INDIA</li>
            
            <li className='my-3'><h5>NUR IZURA UDZIR</h5>UNIVERSITY OF PUTRA MALAYSIA, MALAYSIA</li>
            <li className='my-3'><h5>MOHAMMAD SHARIQ</h5>SHARDA UNIVERSITY, GREATER NOIDA, INDIA </li>
           
            {/* <li className='my-3'><h5>ANIL K SAGGAR</h5>SHARDA UNIVERSITY, GREATER NOIDA, INDIA </li> */}
            <li className='my-3'><h5>UPASANA DOHARE</h5>GALGOTIAS UNIVERSITY, UP, INDIA</li>

            <li className='my-3'><h5>PRASHANT JOHRI </h5> GALGOTIAS UNIVERSITY, GREATER NOIDA, INDIA</li>

            <li className='my-3'><h5>ALOK KATIYAR</h5> GALGOTIAS UNIVERSITY, GREATER NOIDA, INDIA</li>
            
        </ul>

        <ul className="my-3">
            <h3><span class="badge rounded-pill text-bg-success">ADVISORY COMMITTEE </span></h3>

            <li className='my-3'><h5>BHARAT K. BHARGAVA</h5> DEPARTMENT OF COMPUTER SCIENCES, PURDUE UNIVERSITY, INDIANA, USA.</li>
            
            
            <li className="my-3"> <h5>SONAJHARIA MINZ</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>
            
            <li className="my-3"> <h5>R. K. AGRAWAL</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>
            <li className='my-3'><h5>A. HANAN ABDULLAH</h5> FACULTY OF COMPUTING, UNIVERSITY TECHNOLOGY MALAYSIA, JOHOR BAHRU, MALAYSIA</li>
            <li className="my-3"> <h5>D. P. VIDYARTHI</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>
            <li className="my-3"> <h5>SATISH CHAND</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>
            <li className="my-3"><h5>AIME-LAY-EKUAKILLE</h5> UNIVERSITY OF SALENTO, LECCE ITALY</li>

            
            <li className="my-3"> <h5>T. V. VIJAY KUMAR</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>

            <li className="my-3"> <h5>VIR BAHADUR SINGH</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>
            <li className="my-3"> <h5>RATNESHWER</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>
            <li className="my-3"> <h5>MANJU KHARI</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>
            <li className="my-3"> <h5>PIYUSH PRATAP SINGH</h5> SCHOOL OF COMPUTER & SYSTEMS SCIENCES, JNU, NEW DELHI, INDIA</li>

        </ul>

        <h1 className="border-bottom">TECHNICAL PROGRAM COMMITTEE</h1>

        <h3><span class="badge rounded-pill text-bg-success my-4">International</span></h3>

        <ul className='my-4'>
    
            <li className="my-3"><h5>ALAIN BRETTO</h5> Computer Science Department, University of Caen, France</li>
            {/* <li className="my-3"><h5>ANISH MATHURIA</h5> CIS, University of Massachusetts, Dartmouth, USA</li> */}
            <li className="my-3"><h5>ATIF AZAD</h5> Ericsson Telekommunikation GmbH & Co KG, Frankfurt, Germany</li>
            <li className="my-3"><h5>RAFIK HAMZA</h5> TOKYO INTERNATIONAL UNIVERSITY (TIU), TOKYO, JAPAN.</li>
            <li className="my-3"><h5>CHENG-CHI LEE</h5>  FU JEN CATHOLIC UNIVERSITY, Taiwan</li>

            <li className="my-3"><h5>MOHAMMAD SHOJAFAR</h5>  UNIVERSITY OF SURREY, GUILDFORD, UNITED KINGDOM.</li>
            <li className="my-3"><h5>ARIJIT KARATI</h5>  NATIONAL SUN YAT-SEN UNIVERSITY, TAIWAN</li>
            <li className="my-3"><h5>KHALID MAHMOOD</h5>  NATIONAL YUNLIN UNIVERSITY OF SCIENCE AND TECHNOLOGY, YUNLIN, TAIWAN.</li>
            <li className="my-3"><h5>MADHUSANKA LIYANAGE</h5> SCHOOL OF COMPUTER SCIENCE, UNIVERSITY COLLEGE DUBLIN, IRELAND.</li>

            
            
            
            
            <li className="my-3"><h5>OMPRAKASH KAIWARTYA</h5> NOTTINGHAM TRENT UNIVERSITY, CLIFTON CAMPUS, NOTTINGHAM, UK</li>
            
            
            
            <li className="my-3"><h5>CARLOS BECKER WESTPHALL</h5> IS, Federal University of Santa Catarina, Brazil</li>
            <li className="my-3"><h5>SHABANA UROOJ</h5>Princess Nourah bint Abdulrahman University, Saudi Arabia</li>
            
            <li className="my-3"><h5>DIMITRIOS A. KARRAS</h5> NATIONAL AND KAPODISTRIAN UNIVERSITY OF ATHENS, GREECE</li>
            <li className="my-3"><h5>EISUKE KITA</h5> CSS, SCHOOL OF IS, NAGOYA UNIVERSITY, JAPAN</li>
            <li className="my-3"><h5>GAUTAM TIWARI</h5> Accenture LLP, Bloomington Illinois, USA</li>
            <li className="my-3"><h5>GUOYI PENG</h5> COE, Nihon University, Fukushima, Japan</li>
            <li className="my-3"><h5>HASMUKH MORARJI</h5> EE & CS, Queensland University of Technology, Brisbane, Australia</li>
            <li className="my-3"><h5>H. J. KAMARUZAMAN JUSOFF</h5> University of Putra Malaysia, Ehsan, Malaysia</li>
            <li className="my-3"><h5>IMRAN BASHIR BHATTI</h5> Red-Database-Security GmbH, Frankfurt, Germany</li>
            <li className="my-3"><h5>JAY SINGH</h5> Chonbuk National University, Jeonju, Jeonbuk, Korea</li>
            <li className="my-3"><h5>MADAN SINGH</h5> National University of Lesotho, Roma</li>
            <li className="my-3"><h5>MAODE MA</h5> COLLEGE OF ENGINEERING, QATAR UNIVERSITY, QATAR</li>
            <li className="my-3"><h5>MICHAEL COYLE</h5> East Carolina University, Greenville, USA</li>
            <li className="my-3"><h5>NEHA GUPTA</h5> Dell, Phoenix, USA</li>
            <li className="my-3"><h5>NIKA RAATIKAINEN</h5> ES, University of Eastern Finland, Finland</li>
            <li className="my-3"><h5>MOHAMMAD HASHIM</h5> Hughes Network System GmbH, Griesheim, Germany</li>
            <li className="my-3"><h5>MOSTAFA FOUDA</h5> GSIS, Tohoku University, Japan</li>
            <li className="my-3"><h5>NALIN SARDA</h5> School of Engineering and Science, Victoria University, Australia</li>
            <li className="my-3"><h5>RASHIDALI</h5> Taif University, Saudi Arabia</li>
            <li className="my-3"><h5>SAIKISHOPR ELANGOVAN</h5> Chief IO & NE, Techno Forum Software Solutions, Sydney, Australia</li>
            <li className="my-3"><h5>SANDEEP LOHANI</h5> Department of Economic Security, Phoenix, USA</li>
            <li className="my-3"><h5>SANJAY AGARWAL</h5> Cypress Semiconductor, Taipei, Taiwan</li>
            <li className="my-3"><h5>SANJAY SINGH</h5> EADS Deutschland GmbH, Ulm, Germany</li>
            <li className="my-3"><h5>SHAHID AKRAM</h5> Quipi GmbH, Frankfurt, Germany</li>
            <li className="my-3"><h5>SHAZIL KAMAL SHAH</h5> CDM Australia, Sydney, Australia</li>
            



            <li className="my-3"><h5>YUE CAO</h5> WUHAN UNIVERSITY WUHAN, CHINA</li>
            <li className="my-3"><h5>H.J. KAMARUZAMAN JUSOFF</h5> UNIMAS, MALAYSIA</li>
            <li className="my-3"><h5>MIKA RAATIKAINEN</h5> ES, UNIVERSITY OF EASTERN FINLAND</li>
            <li className="my-3"><h5>TARUN JAGANI</h5> Scalaris AG, Villingen, Germany</li>


            <li className="my-3"><h5>THTIKAN BOONKANG</h5> Faculty of Engineering, Ubon Ratchathani University, Ratchathani, Thailand</li>
            <li className="my-3"><h5>V. LAKSHMI NARASIMHAN</h5> CSE, EAST CAROLINA UNIVERSITY, GREENVILLE, USA</li>
            <li className="my-3"><h5>VIRENDRA C. BHAVSAR</h5> University of New Brunswick, Fredericton, Canada</li>
            <li className="my-3"><h5>WINFRIED E. KUEHNHAUSER</h5> Computer Science, Technical University of Ilmenau, Germany</li>
            <li className="my-3"><h5>ZIYAD AL-KHINALIE</h5> Information Technology, Multimedia University, Selangor, Malaysia</li>
        </ul>

    
        <h3><span class="badge rounded-pill text-bg-success">National</span></h3>
        <ul className="my-4">
            <li className="my-3"><h5>ABHAY KUMAR</h5> INDIAN INSTITUTE OF INFORMATION TECHNOLOGY AGARTALA, INDIA</li>

            
            <li className="my-3"><h5>PARMA NAND ASTYA</h5> SHARDA UNIVERSITY, INDIA</li>

            <li className="my-3"><h5>ANURAG JAIN</h5>GURU GOBIND SINGH INDRAPRASTHA UNIVERSITY, INDIA</li>



            <li className="my-3"><h5>ABHIJEET MAITRA</h5> IIT Guwahati, India</li>
            <li className="my-3"><h5>AJAY KUMAR SHARMA</h5> DR B R AMBEDKAR NATIONAL INSTITUTE OF TECHNOLOGY JALANDHAR, PUNJAB, India</li>
            <li className="my-3"><h5>JALAJ SHARMA</h5> G.B PANTNAGAR UNIVERSITY</li>
            <li className="my-3"><h5>APARAJITA OJHA</h5> Director, IIIT Jabalpur, M.P., India</li>
            <li className="my-3"><h5>B. RAMADOSS</h5> CSE, NIT, Tiruchirappalli, T.N., India</li>
            <li className="my-3"><h5>B. B. AMBERKER</h5> CSE, NIT Warangal, A.P., India</li>
            <li className="my-3"><h5>RAJENDRA KUMAR</h5>JAMIA MILLIA ISLAMIA, DELHI INDIA</li>

            <li className="my-3"><h5>RAM SHRINGAR RAW</h5>NSUT , DELHI, INDIA</li>
            <li className="my-3"><h5>SAHADE PADHYE</h5>MOTILAL NEHRU NATIONAL INSTITUTE OF TECHNOLOGY ALLAHABAD, INDIA</li>
            <li className="my-3"><h5>SANJOY DAS</h5>INDIRA GANDHI NATIONAL TRIBAL UNIVERSITY, INDIA</li>
            
            <li className="my-3"><h5>DHEERENDRA MISHRA</h5> MAULANA AZAD NATIONAL INSTITUTE OF TECHNOLOGY (MANIT), BHOPAL.</li>
            <li className='my-3'><h5>S. K SINGH</h5>CCET, CHANDIGARH, PUNJAB, INDIA</li>
            <li className="my-3"><h5>BRAHMJIT SINGH</h5> ECE, NIT Kurukshetra, India</li>
            <li className="my-3"><h5>CHALLA RAMA KRISHNA</h5> CSE, NITTTR (Ministry of HRD, Govt. of India), India</li>
            <li className="my-3"><h5>D. K. YADAV</h5> CSE, NIT Jamshedpur, India</li>
            <li className="my-3"><h5>ANIL KUMAR SAGAR</h5> Sharda University, India</li>

            
            
            <li className="my-3"><h5>RAJESH MISHRA</h5>G.B.U, GREATER NOIDA, INDIA</li>
            <li className="my-3"><h5>DHARMENDER KUMAR</h5> AI AND DS, GJUST, HISAR, INDIA</li>
           
            <li className="my-3"><h5>NAVAID ZAFAR RIZVI</h5>G.B.U, GREATER NOIDA, INDIA</li>
            
            <li className="my-3"><h5>MAYANK SINGH</h5>SCHOOL OF ENGINEERING, G.B.U, GREATER NOIDA, INDIA</li>
            <li className="my-3"><h5>ABDUL WAHID</h5>MAULANA AZAD NATIONAL URDU UNIVERSITY, HYDERABAD, INDIA</li>
            <li className="my-3"><h5>NANHAY SINGH</h5>NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY, INDIA</li>
            <li className="my-3"><h5>SARTAJ UL HASAN</h5>INDIAN INSTITUTE OF TECHNOLOGY JAMMU, INDIA</li>
            <li className="my-3"><h5>B.B. AMBERKER</h5>NATIONAL INSTITUTE OF TECHNOLOGY, WARANGAL, TELANGANA, INDIA</li>
            <li className="my-3"><h5>D.K. YADAV</h5>NIT JAMSHEDPUR, INDIA</li>
            <li className="my-3"><h5>M.M. SUFYAN BEG</h5>JAMIA MILLIA ISLAMIA UNIVERSITY, NEW DELHI, INDIA</li>

            <li className="my-3"><h5>N.S. MURTHY</h5>JVRSEC, KANURU, ANDHRA PRADESH, INDIA</li>
            <li className="my-3"><h5>P.C. JHA</h5>UNIVERSITY OF DELHI, DELHI, INDIA</li>
            <li className="my-3"><h5>P.K. SINGH</h5>ABV-INDIAN INSTITUTE OF INFORMATION TECHNOLOGY AND MANAGEMENT GWALIOR</li>
            <li className="my-3"><h5>R.B. MISHRA</h5>INDIAN INSTITUTE OF TECHNOLOGY, KHARAGPUR, INDIA</li>
            <li className="my-3"><h5>S.K. CHATURVEDI</h5>INDIAN INSTITUTE OF TECHNOLOGY, KHARAGPUR, INDIA</li>
            <li className="my-3"><h5>S.S. PATNAYAK</h5>NITTTR Chandigarh, INDIA</li>

            <li className="my-3"><h5>BHUPERDRA GUPTA</h5> IIIT JABALPURE, M.P., INDIA</li>
            <li className="my-3"><h5>D.K. MISHRA</h5>S.G.S.I.T.S., Indore M.P., INDIA</li>
            <li className="my-3"><h5>M.P. SEBASTIAN</h5>IIM KOZHIKODE, INDIA</li>
           

            
            

            <li className="my-3"><h5>INDRANIL SEN GUPTA</h5> CSE, Indian Institute of Technology, Kharagpur, India</li>
            <li className="my-3"><h5>K. B. MISHRA</h5> IIT Kharagpur, India</li>
            <li className="my-3"><h5>MADHU JAIN</h5> INDIAN INSTITUTE OF TECHNOLOGY ROORKEE, U.K., India</li>
            <li className="my-3"><h5>MAHESH CHANDRA</h5> BIT Mesra, Ranchi, India</li>
            <li className="my-3"><h5>M. M. SUFYAN BEG</h5> CSE, Jamia Millia Islamia University, New Delhi, India</li>
            <li className="my-3"><h5>MOHD KAMIL</h5> University Polytechnic CS, Jamia Millia Islamia, New Delhi, India</li>
            <li className="my-3"><h5>N. S. MURTHY</h5> ECE, NIT, Warangal, A.P., India</li>
            <li className="my-3"><h5>P. VENKATARAM</h5> ECE, IISc, Bangalore, India</li>


            <li className="my-3"><h5>ARVIND</h5>HANSRAJ COLLEGE ( DELHI UNIVERSITY) , INDIA</li>
            <li className="my-3"><h5>B. B. SAGAR</h5>HBTU, KANPUR, U.P, INDIA</li>
            
            <li className="my-3"><h5>S. H. ABBAS MEHDI</h5> STPI MINISTRY OF IT, GOVT. OF INDIA, NOIDA, INDIA</li>
            <li className="my-3"><h5>S. TUSHIR</h5> DEEYA ENERGY INDIA, GURGAON, INDIA</li>
            <li className="my-3"><h5>AJEET K. PANDEY</h5>COGNIZANT TECHNOLOGY SOLN., HYDERABAD</li>
            <li className="my-3"><h5>SANJAY GUPTA</h5> SAMSUNG INDIA LTD. NOIDA, INDIA</li>
            <li className="my-3"><h5>MOHD. ASHRAF SAIFI</h5> MAULANA AZAD NATIONAL URDU UNIVERSITY, HYDERABAD, INDIA</li>
            <li className="my-3"><h5>KHALEEL AHMAD</h5>MAULANA AZAD NATIONAL URDU UNIVERSITY, HYDERABAD, INDIA</li>
            <li className="my-3"><h5>MANOJ KUMAR GUPTA</h5> SMVD, NEW DELHI</li>
            <li className="my-3"><h5>ANISH MATHURIA</h5> DA-IICT, GUJARAT, INDIA</li>
            {/* <li className="my-3"><h5>P. VENKATARAM</h5> ECE, IISc, Bangalore, India</li>
            <li className="my-3"><h5>P. VENKATARAM</h5> ECE, IISc, Bangalore, India</li>
            <li className="my-3"><h5>P. VENKATARAM</h5> ECE, IISc, Bangalore, India</li>
            <li className="my-3"><h5>P. VENKATARAM</h5> ECE, IISc, Bangalore, India</li> */}


            <li className="my-3"><h5>P. C. JAIN</h5> Scientist, C-DAC Centre, Noida, India</li>
            <li className="my-3"><h5>P. C. JHA</h5> DU, New Delhi, India</li>
            <li className="my-3"><h5>P. K. SINGH</h5> IITM Gwalior (M.P.), India</li>
            <li className="my-3"><h5>PREETAM KUMAR</h5> IIT Patna, India</li>
            <li className="my-3"><h5>R. B. MISHRA</h5> IIT Kharagpur, India</li>
            <li className="my-3"><h5>RAJENDRA K. ASTHANA</h5> SDS Software, Noida, India</li>
            <li className="my-3"><h5>RAKESH MAHESHWAR</h5> STQC Ministry of Communication & IT (Govt. of India), New Delhi, India</li>
            <li className="my-3"><h5>RAMA MURTHY G.</h5> IIIT Hyderabad, A.P., India</li>
            <li className="my-3"><h5>RAMESH B. GHODGAONKAR</h5> EE, MANIT Bhopal, M.P., India</li>
            <li className="my-3"><h5>RANJIT BISWAS</h5> CSE, Jamia Hamdard, New Delhi, India</li>
            <li className="my-3"><h5>S. B. SABBARWAL</h5> IIT Delhi, India</li>
            <li className="my-3"><h5>S. K. CHATURVEDI</h5> IIT Kharagpur, India</li>
            <li className="my-3"><h5>S. S. PATNAYAK</h5> NITTR Chandigarh, India</li>
            <li className="my-3"><h5>S. S. RAJPUT</h5> NPL, New Delhi, India</li>
           
            <li className="my-3"><h5>SANJEEV TOKEKAR</h5> Devi Ahilya University, Indore, Madhya Pradesh, India</li>
            <li className="my-3"><h5>UDAISHANKER</h5> CSE, MMMEC Gorakhpur, India</li>
            <li className="my-3"><h5>AKHILESH R. UPADHYAY</h5> SIRT, Bhopal, India</li>
            <li className="my-3"><h5>ALEEM ALI</h5> University Polytechnic CS, JMIU, New Delhi, India</li>
            <li className="my-3"><h5>AMITABH NAAG</h5> Academy of Technology, Kolkata, India</li>
            <li className="my-3"><h5>ASHOK G. AMBEKAR</h5> S.G.S.I.T.S., Indore, M.P., India</li>
            <li className="my-3"><h5>BHOOPENDRA DWIVEDI</h5> VITS Ghaziabad, UP, India</li>
            <li className="my-3"><h5>BHUPENDRA GUPTA</h5> CSE, IIIT Jabalpur, M.P., India</li>
            <li className="my-3"><h5>D. A. ASHOKO</h5> SJB Institute of Technology, Bangalore, India</li>
            <li className="my-3"><h5>D. K. MISHRA</h5> S.G.S.I.T.S., Indore, M.P., India</li>
            <li className="my-3"><h5>DEBENDRA K. PANDA</h5> Medi-Caps University, M.P., India</li>
            <li className="my-3"><h5>DHARM RAJ</h5> SHARDA UNIVERSITY, Delhi, India</li>
            <li className="my-3"><h5>EDWIN VIJAY KUMAR</h5> Vizag, Vishakhapatnam, India</li>
            <li className="my-3"><h5>GOLDEN RAYMOND B</h5> Scientist, C-DAC Centre, Mumbai, India</li>
            <li className="my-3"><h5>IRFAN AHMAD KHAN</h5> Lancaster University, U.K.</li>
            <li className="my-3"><h5>J. P. SAINI</h5> M.M.M. Engineering College, Gorakhpur, India</li>
            <li className="my-3"><h5>JAYSHREE VAJPAI</h5> JNV University, Jodhpur, India</li>
            <li className="my-3"><h5>JYOTI PRAKASH SINGH</h5> NIT Patna, Bihar, India</li>
            <li className="my-3"><h5>M. P. SEBASTIAN</h5> CSE, National Institute of Technology, Calicut, India</li>
            <li className="my-3"><h5>MAYANK MISHRA</h5> DRDL, Hyderabad, A.P., India</li>
            <li className="my-3"><h5>MURLIDHAR KULKARNI</h5> ECE, NIT Karnataka, India</li>
            <li className="my-3"><h5>NITIN SHARMA</h5> Scientist, Bharat Electronics Limited, Noida, India</li>
            <li className="my-3"><h5>PRATIKSHA SAXENA</h5> School of VSAS, GBU, Greater Noida, India</li>
            
            <li className="my-3"><h5>RAAZIYAH SHAMIM</h5> JIIT, Noida, India</li>
            <li className="my-3"><h5>RAJEEV KUMAR</h5> JIIT, Wagnaghat, India</li>
            <li className="my-3"><h5>RAJESH KHANNA</h5> Thapar University, Patiala, India</li>
            <li className="my-3"><h5>RAMANUJAN</h5> VIT Vellore, India</li>
            <li className="my-3"><h5>RANVIJAY SINGH</h5> MNNIT, Allahabad, India</li>
            <li className="my-3"><h5>RISHIKESH PATANKAR</h5> Media Lab Asia, New Delhi, India</li>
            <li className="my-3"><h5>S. KAZIM NAQVI</h5> CIT, Jamia Millia Islamia, New Delhi, India</li>
            <li className="my-3"><h5>S. S. SRIDHAR</h5> CSE, SRM University, Kattankulathur, Tamil Nadu, India</li>
            <li className="my-3"><h5>SABA AKHTAR</h5> NIC, Ministry of Communication & IT (Govt. of India), New Delhi, India</li>
            <li className="my-3"><h5>SAIBAL K. PAL</h5> CSE, University of Delhi, Delhi, India</li>
            <li className="my-3"><h5>SAIBAL KUMAR PAL</h5> Senior Scientist, DRDO, Delhi, India</li>
            <li className="my-3"><h5>SANJEEV KUMAR</h5> Indian Institute of Technology Roorkee, U.K., India</li>
            <li className="my-3"><h5>SANJIB ANSEKHAR ROY</h5> School of CSE, VIT University, Tamil Nadu, India</li>
            
            <li className="my-3"><h5>SARSIJ TRIPATHI</h5> MNNIT, Allahabad, India</li>
            <li className="my-3"><h5>SATHISH BABU B.</h5> CSE, Siddaganga Institute of Technology, Tumkur, Karnataka, India</li>
            <li className="my-3"><h5>SHIRSHU VARMA</h5> IIIT Allahabad, India</li>
            <li className="my-3"><h5>SHYAM LAL</h5> MIT Moradabad, India</li>
            <li className="my-3"><h5>SUBHAMAY MAITRA</h5> Indian Statistical Institute, Calcutta, India</li>
            {/* <li className="my-3"><h5>SUDHEER KUMAR SHARMA</h5> SCSS, JNU, New Delhi, India</li> */}
            <li className="my-3"><h5>SUKUMAR NANDI</h5> CSED, Indian Institute of Technology, Guwahati, India</li>
            <li className="my-3"><h5>U. B. S CHANDRAWAT</h5> ECE, SVCE, Indore, India</li>
            <li className="my-3"><h5>VINOD PANDEY</h5> G.E., Bangalore, India</li>
            <li className="my-3"><h5>VRINDA TOKEKAR</h5> CSIT, Devi Ahilya University, Indore, M.P., India</li>
        </ul>
 


    </div>

    // <div className="container my-5">
    //     <h5 className="border-bottom">Organizing Committee Member</h5>
    //     <div className="row row-cols-1 row-cols-md-3 g-4 my-5">

    //         {Members.map((member, index)=>(

    //             <div className="col" key={member.id}>
    //                 <div className="card h-100">
    //                     <img src={JNU} className="card-img-top" alt="..." height="300px" />
    //                     <div className="card-body" key={member.id}>
    //                         <h5 className="card-title" >{member.Name}</h5>
    //                         <p className="card-text" >{member.Designation}</p>
    //                         <p className="card-text" >{member.School}</p>
    //                         <p className="card-text" >{member.University}</p>
    //                         <p className="card-text" >{member.State}</p>
    //                         <p className="card-text" >{member.Country}</p>
            
    //                     </div>
    //                 </div>
    //             </div>
    //         ))}

    //         </div>
    // </div>
  )
}

export default Steering